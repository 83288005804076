import AcoDefaultSEO from '@components/SEO/AcoDefaultSEO';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { GoogleTagManager } from '@next/third-parties/google'
import { CustomSnackbarProvider } from '@providers/CustomSnackbar.provider';
import NotificationsProvider from '@providers/notifications/NotificationsContext';
import { SessionContextProvider, useSession } from '@providers/user/SessionContext';
import { isProduction } from '@shared/environment';
import { GA } from '@shared/ga/ga';
import { AppProps } from 'next/app';
import '../styles/globals.scss';
import '../styles/filepicker.css';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { CookiesProvider } from 'react-cookie';

import acoTheme from '../styles/theme';


function AcoApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { user: user } = useSession();

  React.useEffect(() => {
    /*if (typeof window !== 'undefined' && isProduction) {
      ReactPixel.init(process.env.NEXT_PUBLIC_PIXEL_META);
     // ReactPixel.pageView();
    }*/

    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      if (isProduction) {
        GA.pageview(url);
        user && GA.userID(user.id);
        // ReactPixel.pageView();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, user]);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

  }, []);

  React.useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_PIXEL_META);
        ReactPixel.pageView();

        if (isProduction) {
          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        }
      });
  }, [router.events]);


  return (
    <StyledEngineProvider injectFirst>
      <SessionContextProvider>
        <NotificationsProvider>
          <CookiesProvider>
            <ThemeProvider theme={acoTheme}>
              <CssBaseline />
              <CustomSnackbarProvider>
                <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM-WB8VZD95} />
                <AcoDefaultSEO />
                {/* @ts-ignore */}
                <Component {...pageProps} />
              </CustomSnackbarProvider>
            </ThemeProvider>
          </CookiesProvider>
        </NotificationsProvider>
      </SessionContextProvider>
    </StyledEngineProvider>
  );
}

AcoApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default AcoApp;
