export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const pageview = (url: URL) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

const userID = (ID: number | string) => {
  window.gtag('config', GA_TRACKING_ID, {
    'user_id': ID,
  });
};

export type GTagEvent = {
  name: string;
  params: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events

const event = ({ name, params }: GTagEvent) => {
  window.gtag('event', name, params);
};

export const GA = { pageview, userID, event };
