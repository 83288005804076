import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  OptionsObject,
  SnackbarKey,
  SnackbarProvider,
  SnackbarProviderProps,
  useSnackbar,
  VariantType,
} from 'notistack';
import React from 'react';

function important(value: string) {
  return `${value} !important`;
}

const commonVariant: CSSProperties = {
  borderRadius: 8,
  boxShadow: 'none',
  padding: '10px 26px',
  fontSize: 14,
};

const useSnackbarStyle = makeStyles((theme) => ({
  root: {
    padding: 0,
    '& .closeIcon': {
      color: '#272727',
    } as CSSProperties,
    '& svg.icon': {
      marginRight: 5,
    } as CSSProperties,
  },
  containerRoot: {
    top: 100,
  },
  variantError: {
    ...commonVariant,
    backgroundColor: important(theme.palette.error.light),
    color: important(theme.palette.error.main),
  },
  variantSuccess: {
    ...commonVariant,
    backgroundColor: important(theme.palette.success.light),
    color: important(theme.palette.success.main),
  },
  variantWarning: {
    ...commonVariant,
    backgroundColor: important(theme.palette.warning.light),
    color: important(theme.palette.warning.main),
  },
  variantInfo: {
    ...commonVariant,
    backgroundColor: important(theme.palette.info.light),
    color: important(theme.palette.info.main),
  },
}));

const snackbarProviderBaseProps: Partial<SnackbarProviderProps> = {
  maxSnack: 5,
  iconVariant: ['error', 'success', 'warning', 'info', 'default'].reduce(
    (acc, actual) => ({ [actual]: <InfoIcon className='icon' />, ...acc }),
    {}
  ),
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  autoHideDuration: 3000,
};

export const CustomSnackbarProvider: React.FC = (props) => {
  const snackbarStyles = useSnackbarStyle();

  const snackbarRef = React.createRef<SnackbarProvider>();

  const snackbarProviderProps: Partial<SnackbarProviderProps> = {
    ...snackbarProviderBaseProps,
    dense: false,
    classes: { ...snackbarStyles },
    action: (key) => (
      <IconButton onClick={() => snackbarRef.current.closeSnackbar(key)} className='closeIcon'>
        <CloseIcon fontSize='small' />
      </IconButton>
    ),
  };

  return (
    <>
      {/* @ts-ignore */}
      <SnackbarProvider ref={snackbarRef} {...snackbarProviderProps}>
        {props.children}
      </SnackbarProvider>
    </>
  );
};

declare type SnackbarTrigger = (msg: string, props?: OptionsObject) => void;

export interface CustomSnackbarHook {
  snackbars: {
    showError: SnackbarTrigger;
    showWarning: SnackbarTrigger;
    showInfo: SnackbarTrigger;
    showSuccess: SnackbarTrigger;
    show: SnackbarTrigger;
    close: (key?: SnackbarKey) => void;
  };
}

export const useCustomSnackbar: () => CustomSnackbarHook = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const enqueueWithVariant = (variant: VariantType) => {
    return (msg: string, props?: OptionsObject) => enqueueSnackbar(msg, { variant, ...props });
  };

  return {
    snackbars: {
      showError: enqueueWithVariant('error'),
      showWarning: enqueueWithVariant('warning'),
      showInfo: enqueueWithVariant('info'),
      showSuccess: enqueueWithVariant('success'),
      show: enqueueSnackbar,
      close: closeSnackbar,
    },
  };
};
