import theme from '@theme';
import {NextSeo} from 'next-seo';
import React from 'react';


const AcoDefaultSEO: React.VFC = () => {

    const keywords = [
        'agroconsultas',
        'agroconsultas online',
        'agro',
        'agronomía',
        'agronomia',
        'agricultura',
        'agroconsultas argentina',
        'agroconsultas online argentina',
        'agronomia argentina',
        'agronomía argentina',
        'agricultura argentina',
        'información agricultura',
        'informacion agricultura',
        'información agronomia',
        'información agronomía',
        'informacion agronomia',
        'informacion agronomía',
        'expertos agricultura',
        'expertos agronomia',
        'expertos agronomía',
        'artículos agronomía',
        'articulos agronomia',
        'artículos agronomia',
        'articulos agronomía',
        'artículos agricultura',
        'articulos agricultura',
        'documentos agronomia',
        'documentos agronomía',
        'documentos agricultura',
        'cultivo',
        'cultivos',
        'cultivar',
        'cosecha',
        'cosechas',
        'cosechar',
        'Daniel Igarzabal',
        'Daniel Julio Miralles',
        'Daniel Miralles',
        'Diego Batlla',
        'Esteban Jobbagy',
        'Federico Esteban Bert',
        'Federico Bert',
        'Gustavo Angel Maddoni',
        'Gustavo Maddoni',
        'Gustavo Ferraris',
        'Jorge Luis Mercau',
        'Jorge Mercau',
        'Marcelo Anibal Carmona',
        'Marcelo Carmona',
        'Mónica López Pereira',
        'Mónica Pereira',
        'Olga Penalba',
        'Pablo Calviño',
        'Román Augusto Serrago',
        'Román Serrago',
        'control de plagas',
        'plagas',
        'plagas cebada',
        'control de plagas cebada',
        'plagas girasol',
        'control de plagas girasol',
        'plagas maiz',
        'plagas maíz',
        'control de plagas maiz',
        'control de plagas maíz',
        'plagas soja',
        'control de plagas soja',
        'plagas trigo',
        'control de plagas trigo',
        'ecofisiologia de cultivos',
        'ecofisiología de cultivos',
        'ecofisioloia de cultivo cebada',
        'ecofisiología de cultivo cebada',
        'ecofisiologia de cultivo trigo',
        'ecofisiología de cultivo trigo',
        'establecimiento de cultivos',
        'fisiologia de semillas',
        'fisiología de semillas',
        'semillas',
        'semilla',
        'germinación y emergencia',
        'germinacion y emergencia',
        'germinacion',
        'germinación',
        'napas',
        'agua en cultivo',
        'agua y cultivo',
        'tiempo y clima',
        'tiempo y clima cultivo',
        'tiempo y clima cultivos',
        'uso informacion climatica cultivos',
        'uso informacion climatica cultivo',
        'ecofisiologia de cultivo maiz',
        'ecofisiología de cultivo maiz',
        'ecofisiologia de cultivo maíz',
        'ecofisiología de cultivo maíz',
        'fertilizante',
        'fertilizantes',
        'fertilización',
        'fertilizacion',
        'fertilización cebada',
        'fertilizacion cebada',
        'fertilización girasol',
        'fertilizacion girasol',
        'fertilización maiz',
        'fertilizacion maiz',
        'fertilizacion maíz',
        'fertilización maíz',
        'fertilización soja',
        'fertilizacion soja',
        'fertilización trigo',
        'fertilizacion trigo',
        'manejo de agua en sistemas productivos',
        'el agua en sistemas productivos',
        'agua y sistemas productivos',
        'caracterización e identificación de ambientes',
        'caracterización de ambientes',
        'identificación de ambientes',
        'caracterizacion e identificacion de ambientes',
        'control de enfermedades cebada',
        'enfermedades cebada',
        'control de enfermedades girasol',
        'enfermedades girasol',
        'control de enfermedades maíz',
        'control de enfermedades maiz',
        'enfermedades maíz',
        'enfermedades maiz',
        'control de enfermedades soja',
        'enfermedades soja',
        'control de enfermedades trigo',
        'enfermedades trigo',
        'ecofisiologia de cultivo girasol',
        'ecofisiología de cultivo girasol',
        'pronósticos climáticos',
        'pronosticos climaticos',
        'pronósticos meteorológicos',
        'pronosticos meteorologicos',
        'pronósticos climaticos cultivo',
        'pronósticos meteorológicos cultivos',
        'sistemas de producción intensificación',
        'sistemas de produccion intensificacion',
        'sistemas de producción manejo general de cultivos',
        'sistemas de produccion manejo general de cultivos',
        'manejo general de cultivos',
        'diseño experimental y análisis',
        'diseño experimental y análisis cultivos',
        'diseño experimental y analisis agricultura',
        'cultivo soja',
        'cultivo cebada',
        'cultivo maiz',
        'cultivo maíz',
        'cultivo trigo',
        'cultivo girasol',
        'cebada',
        'maiz',
        'maíz',
        'trigo',
        'girasol',
        'soja',
        'consultas agronomía',
        'consultas agronomia',
        'consultas a profesionales agronomia',
        'consultas a profersionales agronomía',
        'consultas a profesionales cultivos',
        'consultas cultivos',
        'consultas a empresas agronomía',
        'consultas a empresas agricultura',
        'consultas a profesionales agricultura',
        'consutlas a empresas cultivos',
        'fingicidas',
        'herbicidas',
        'insecticidas',
        'curasemillas.',
    ];

    const DEFAULT_SEO = {
        titleTemplate: '%s | Agroconsultas Online',
        defaultTitle: 'Agroconsultas Online',
        description: 'En Agroconsultas online encontrarás los mejores expertos y la mejor información del agro a un click.',
        canonical: 'https://www.agroconsultasonline.com.ar/',
        themeColor: theme.palette.primary.main,
        openGraph: {
            url: 'https://www.agroconsultasonline.com.ar/',
            title: 'Agroconsultas Online',
            description: 'En Agroconsultas online encontrarás los mejores expertos y la mejor información del agro a un click.',
            images: [
                {
                    url: 'https://www.agroconsultasonline.com.ar/img/V2/portada_redes_sociales.jpg',
                    width: 280,
                    height: 93,
                    alt: 'Agroconsultasonline'
                }
            ],
            site_name: 'Agroconsultasonline',
        },
        additionalMetaTags: [
            {name: 'keywords',
                content: keywords.join(',')},
            {name: 'viewport',
                content: 'width=device-width, initial-scale=1'},
            {name: 'theme-color',
                content: theme.palette.primary.main},
        ],
        additionalLinkTags: [
            { rel: 'apple-touch-icon',
                href: 'www.agroconsultasonline.com.ar/img/favicon/apple-touch-icon.png',
                sizes: '180x180'},
            { rel: 'mask-icon',
                href: 'https://www.agroconsultasonline.com.ar/img/favicon/safari-pinned-tab.svg',
                color: theme.palette.primary.main,
                sizes: 'any'},
            { rel: 'google-site-verification',
                href: 'OuwM9ST_LsnXNKqnQmOdjydw-aYgEj4hM7kzGkIq7CU'},
        ],
        ApplicationCategory: 'SocialNetworking' && 'Educational' && 'Communication' && 'Utilities',
        twitter: {
            handle: '@agroconsultas',
            site: '@agroconsultas',
            cardType: 'summary_large_image',
        },
        facebook: {
            appId: '908028202579104',
        },
    }

    return <NextSeo {...DEFAULT_SEO} />
}


export default AcoDefaultSEO;