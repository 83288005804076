import { createTheme, Theme } from '@mui/material/styles';

const theme: Theme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#26A65B',
        dark: '#0A803B',
        light: '#7CE2A7',
      },
      secondary: {
        main: '#2270A8',
        dark: '#013F6B',
        light: '#5A9CCB',
      },
      text: {
        primary: '#3A3A3A',
        secondary: '#828282',
        disabled: '#DCDCDC',
      },
      background: {
        default: '#F8F8F8',
        paper: '#F8F8F8',
      },
      success: {
        main: '#009423',
        light: '#E4FFEB',
      },
      warning: {
        main: '#F6C722',
        light: '#FFF4CF',
      },
      error: {
        main: '#DA1414',
        light: '#FEEFEF',
      },
      info: {
        main: '#2E5AAC',
        light: '#EEF2FA',
      },
      grey: {
        500: '#DCDCDC',
      },
      ticket: {
        main: '#54A021',
        light: 'rgba(84, 160, 33, 0.5)',
        contrastText: 'rgba(168 , 208, 142)'
      },
      post: {
        main: '#9089D9',
        light: 'rgba(144, 137, 217, 0.5)',
        contrastText: 'rgba(194, 192, 219)'
      },
      document: {
        main: '#E6B91E',
        light: 'rgba(230, 185, 30, 0.5)',
        contrastText: 'rgba(246, 220, 130)'
      },
      sponsored: {
        main: '#CFCFCF',
      },
      newsletter: {
        main: '#E76618',
        light: 'rgba(231, 102, 24, 0.5)',
        contrastText: 'rgba(239, 177 ,140)'
      },
      event: {
        main: '#DA1414',
        light: 'rgb(218 20 21 / 66%)',
        contrastText: 'rgba(234, 102, 102)'
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'Rubik',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),

      h1: {
        fontSize: '96px',
        lineHeight: '112px',
        fontWeight: 300,
      },
      h2: {
        fontSize: '60px',
        lineHeight: '72px',
        fontWeight: 300,
      },
      h3: {
        fontSize: '48px',
        lineHeight: '56px',
        fontWeight: 400,
      },
      h4: {
        fontSize: '34px',
        lineHeight: '36px',
        fontWeight: 400,
      },
      h5: {
        fontSize: '24px',
        lineHeight: '24px',
        fontWeight: 400,
      },
      h6: {
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 500,
      },
      body1: {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 400,
      },
      body2: {
        fontSize: '12px',
        lineHeight: '20px',
        fontWeight: 400,
      },
      button: {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
      },
      overline: {
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: 400,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  }
);

export default theme;
