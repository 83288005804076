import React, {createContext, useContext, useState} from 'react';

interface NotificationsContextInterface {
  updateNotifications?: (value: boolean) => void;
  allReadNotifications?: boolean;
}

const useNotifications: () => NotificationsContextInterface = () => {

  const [allReadNotifications, setAllReadNotifications] = useState<boolean>(false);

  const updateNotifications = (value: boolean) => {
    setAllReadNotifications(value);
  };

  return {
    allReadNotifications,
    updateNotifications
  };
};


const NotificationsProvider: React.FC = ({ children }) => {
  const notifications = useNotifications();

  return (
      <NotificationsContext.Provider value={notifications}>
        {children}
      </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
export const NotificationsContext = createContext<NotificationsContextInterface>(null);

export const useNotificationsContext = () => useContext(NotificationsContext);